<template>
  <div>
    <div class="section-header d-none d-lg-flex">
      <div class="header-title">Thông báo</div>
    </div>
    <hr class="d-none d-lg-block mb-lg-4" />
    <div id="infinite-list" v-if="init">
      <div class="empty-notify" v-if="listAllNotifications.length === 0">
        <p>Bạn chưa có thông báo nào</p>
      </div>
      <div v-else>
        <p class="mb-2 color-grey pt-2">{{ listAllNotifications[0].notify_date }}</p>
        <div v-for="(item, index) in listAllNotifications" :key="item.id" class="mb-md-2">
          <p
            v-if="
              listAllNotifications[index - 1]
                ? listAllNotifications[index - 1].notify_date !== listAllNotifications[index].notify_date
                : false
            "
            class="mb-2 color-grey pt-2"
          >
            {{ item.notify_date }}
          </p>
          <a @click="handlePressNoti(item)" class="d-block">
            <div class="d-flex boxPageItemNotify">
              <div class="mr-2 mt-1 mt-lg-0">
                <img :src="item.image_url" width="40" height="40" class="obfit rounded50" alt />
              </div>
              <div class="w-80">
                <p class="color-black-1 font-weight-600">{{ item.title }}</p>
                <p class="color-grey fontSize13">
                  <span>{{ item.content }}</span>
                </p>
                <p class="mt-1 fontSize13" style="color:#666666">
                  {{ formatDate(item.notify_time, 'HH:mm:ss') }}
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center mb-3" v-if="loading">
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <button
      class="btn btn-light d-none d-lg-flex justify-content-center show-more-btn"
      :disabled="loading"
      @click="loadMore"
      v-if="listAllNotifications.length === 0 ? false : nextItem"
    >
      XEM THÊM
    </button>
    <!-- <modal-notification
      @updateModalNotification="updateModalNotification"
      :showModalNotification="showModalNotification"
    /> -->
  </div>
</template>

<script>
// import PaymentService from '@/api/payment';
// const Swal = require('sweetalert2');
import NotificationService from '../../api/notification';
// import ModalNotification from '../ModalNotification';
import { _savedPosition } from '../../js/router';
export default {
  layouts: 'Deposit',
  // components: { ModalNotification },
  data() {
    return {
      totalPage: 100,
      // nextItem: true,
      loading: true,
      init: false,
      items: [],
      timeOut: null,
      idList: [],
      // pagination: {
      //   currenPage: 1,
      //   pageSize: 10
      // },
      showModalNotification: false
    };
  },
  computed: {
    listAllNotifications: {
      get() {
        return this.$store.getters.getterlistPageNotification;
      },
      set(value) {
        this.$store.commit('setListPageNotification', value);
      }
    },
    pagination: {
      get() {
        return this.$store.getters.getterSavePaginationNotiPage;
      },
      set(value) {
        this.$store.commit('setSavePaginationNotiPage', value);
      }
    },
    nextItem: {
      get() {
        return this.$store.getters.getterSavePaginationNotiPage.nextItem;
      },
      set(value) {
        this.$store.commit('setSavePaginationNotiPage', {
          ...this.pagination,
          nextItem: value
        });
      }
    },
    isSeen() {
      return this.listNotifications.find((x) => !x.viewed_notify);
    },
    listNotifications: {
      get() {
        return this.$store.state.listNotifications;
      },
      set(value) {
        this.$store.commit('setlistNotifications', value);
      }
    },

    isOpenPopupCongrat: {
      get() {
        return this.$store.getters.getterIsOpenPopupCongrat;
      },
      set(newVal) {
        return this.$store.commit('setIsOpenPopupCongrat', newVal);
      }
    },
    popupCongrateData: {
      get() {
        return this.$store.getters.getterPopupCongrateData;
      },
      set(newVal) {
        return this.$store.commit('setPopupCongrateData', newVal);
      }
    },
    isOpenPopupGiftTicket: {
      get() {
        return this.$store.getters.getterIsOpenPopupGiftTicket;
      },
      set(newVal) {
        return this.$store.commit('setIsOpenPopupGiftTicket', newVal);
      }
    },
    popupGiftTicketData: {
      get() {
        return this.$store.getters.getterPopupGiftTicketData;
      },
      set(newVal) {
        return this.$store.commit('setPopupGiftTicketData', newVal);
      }
    },
    isOpenPopupThank: {
      get() {
        return this.$store.getters.getterIsOpenPopupThank;
      },
      set(newVal) {
        return this.$store.commit('setIsOpenPopupThank', newVal);
      }
    },
    popupThankData: {
      get() {
        return this.$store.getters.getterPopupThankData;
      },
      set(newVal) {
        return this.$store.commit('setPopupThankData', newVal);
      }
    }
  },
  mounted() {
    if (_savedPosition[this.$route.fullPath]) {
      window.scrollTo(0, _savedPosition[this.$route.fullPath].y);
    }
  },

  created() {
    window.addEventListener('scroll', this.handlerScroll);
    if (this.listAllNotifications.length > 10) {
      this.loading = false;
      this.init = true;
    } else {
      this.loadNotification();
    }
    this.$bus.$emit('updateTitleMobile', { profile: 'notification', title: 'THÔNG BÁO' });
    this.handleSeenNoti();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handlerScroll);
  },
  methods: {
    handlePressNoti(item) {
      if (item.isPopup) {
        if (item.customField) {
          this.$store.commit('setIsOpenPopupNotifyMessage', true);
          this.$store.commit('setPopupNotifyMessageData', {
            ...item.customField,
            popup_message: item.customField.popup_message || item.content
          });
          return;
        }
      }
      if (item.handler === 'payment_error') {
        this.$store.commit('setIsOpenPopupPaymentError', true);
        return;
      }
      if (item.handler === 'reward_congrat' || item.handler === 'gift_reward_congrat') {
        this.popupCongrateData = item;
        this.isOpenPopupCongrat = true;
        return;
      }

      if (item.handler === 'gift_success') {
        this.popupGiftTicketData = item;
        this.isOpenPopupGiftTicket = true;
        return;
      }

      if (item.handler === 'buy_reply' || item.handler === 'reward_reply') {
        this.popupThankData = item;
        this.isOpenPopupThank = true;
        return;
      }

      this.$router.push(item.target_url || '/').catch((err) => {
        if (
          err.name !== 'NavigationDuplicated' &&
          !err.message.includes('Avoided redundant navigation to current location')
        ) {
          console.error(err);
        }
      });
    },
    handlerScroll() {
      let bottomOfWindow =
        window.scrollY + window.innerHeight + 100 >= document.documentElement.scrollHeight &&
        window.innerWidth <= this.getMobileThresshold();
      if (bottomOfWindow && !this.isLoading && this.nextItem) {
        clearTimeout(this.timeOut);
        this.timeOut = setTimeout(() => {
          this.loadMore();
        }, 200);
        this.loading = true;
      }
    },
    updateModalNotification(val) {
      this.showModalNotification = val;
    },
    loadMore() {
      if (this.listAllNotifications.length >= 10) {
        const currenPage = parseInt(this.listAllNotifications.length / this.pagination.pageSize) + 1;
        if (this.pagination.currenPage < currenPage) {
          this.pagination.currenPage = currenPage;
        }
      }
      this.loading = true;
      let data = {
        limit: this.pagination.pageSize,
        offset: this.pagination.currenPage
      };
      NotificationService.getAllNotification(this.$store.getters.getterAccountId, data)
        .then((res) => {
          if (res.status) {
            this.pagination.currenPage = this.pagination.currenPage + 1;
            let rs = res.data.notifications
              .filter((data) => !this.idList.includes(data.id))
              .map((data) => {
                let {
                  id,
                  title,
                  target_url,
                  content_format,
                  raw_data,
                  image_url,
                  viewed_notify,
                  notify_time,
                  handler
                } = data;
                raw_data = JSON.parse(raw_data);

                const content = this.formatString(content_format, raw_data);
                const customField = raw_data.custom_field;
                const isPopup = raw_data.is_popup;
                const notify_date = this.getOnlyDate(notify_time);
                this.idList.push(id);
                return {
                  id,
                  title,
                  target_url,
                  content,
                  image_url,
                  viewed_notify,
                  notify_time,
                  notify_date,
                  handler,
                  customField,
                  isPopup,
                  raw_data
                  // lottery_time
                };
              });
            this.listAllNotifications = this.listAllNotifications.concat(rs);
            this.loading = false;
            this.nextItem = res.data.next_page;
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    groupBy(array, key) {
      // Return the end result
      return array.reduce((result, currentValue) => {
        (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
        return result;
      }, {});
    },
    loadNotification() {
      let data = {
        limit: this.pagination.pageSize,
        offset: this.pagination.currenPage
      };
      NotificationService.getAllNotification(this.$store.getters.getterAccountId, data)
        .then((res) => {
          if (res.status) {
            this.listAllNotifications = res.data.notifications.map((data) => {
              let {
                id,
                title,
                target_url,
                content_format,
                raw_data,
                image_url,
                viewed_notify,
                notify_time,
                handler
              } = data;
              raw_data = JSON.parse(raw_data);

              const content = this.formatString(content_format, raw_data);
              const customField = raw_data.custom_field;
              const isPopup = raw_data.is_popup;
              const notify_date = this.getOnlyDate(notify_time);

              this.idList.push(id);
              return {
                id,
                title,
                target_url,
                content,
                image_url,
                viewed_notify,
                notify_time,
                notify_date,
                handler,
                customField,
                isPopup,
                raw_data
                // lottery_time
              };
            });
          }
          this.nextItem = res.data.next_page;
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.init = true;
          this.loading = false;
        });
    },
    setSeenNotify() {
      this.listNotifications.forEach((x, index) => {
        this.listNotifications[index].viewed_notify = 1;
      });
    },
    async handleSeenNoti() {
      if (this.isSeen) {
        const res = await NotificationService.updateSeenNotify(this.$store.getters.getterAccountId).catch((e) => {
          console.error(e);
        });
        if (res.status) {
          this.setSeenNotify();
        }
      }
    }
  }
};
</script>
<style lang="scss">
.boxPageItemNotify {
  overflow: hidden;
  padding: 0.9rem 1rem;
  margin-bottom: 10px;
  border-radius: 3px;
  background-color: #ffffff;
  transition: 0.3s all;
  cursor: pointer;

  &:hover {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
  }
}
.disabled-method {
  opacity: 0.5;
}
.btn-payment {
  height: 40px !important;
  font-size: 0.9375rem !important;
}

.show-more-btn {
  height: 40px;
  border-radius: 3px;
  background-color: #efefef;
  align-items: center;
  font-size: 15px;
  line-height: 20px;
  color: #0d234a;
  border: none;
  width: 100%;
  &:focus {
    box-shadow: none;
    outline: none;
  }
}
</style>
